<template>
  <div>
    <!-- ModalCreatePin -->
    <BModal
      id="modal-check-pin"
      ref="modal-check-pin"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol>
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt="Komerce"
            class="m-auto"
          >
        </BCol>
        <BCol class="text-black text-xl text-center my-1 font-bold">
          Yah! Kamu belum membuat PIN
        </BCol>
        <BCol class="text-[#626262] text-md text-center">
          Untuk membuat kartu perlu memiliki PIN terlebih dahulu. Yuk! Buat PIN kamu sekarang untuk melanjutkan proses pembuatan kartunya!
        </BCol>
        <BRow class="flex flex-row justify-center gap-3 mt-2">
          <BButton
            variant="outline-primary"
            @click="$bvModal.hide('modal-check-pin')"
          >
            Kembali
          </BButton>
          <!-- <BButton
            v-BModal.modal-create-pin
            variant="primary"
          >
            Buat PIN
          </BButton> -->
          <BButton
            variant="primary"
            @click="handlePushToCreatePIN"
          >
            Buat PIN
          </BButton>
        </BRow>
      </BRow>
    </BModal>

    <!-- ModalCreatePin -->
    <BModal
      id="modal-create-pin"
      ref="modal-create-pin"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <h4 class="text-black font-semibold">
            Buat PIN
          </h4>
        </BCol>
        <BCol class="d-flex justify-content-center">
          <div class="text-black text-md">
            Masukkan PIN Kamu
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChangeCreatePin"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2">
          <BButton
            v-BModal.modal-confirm-pin
            variant="primary"
            :disabled="pin === '' || pin.length < 6"
          >
            Buat PIN
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <!-- ModalConfirmCreatePin -->
    <BModal
      id="modal-confirm-pin"
      ref="modal-confirm-pin"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <h4 class="text-black font-semibold">
            Buat PIN
          </h4>
        </BCol>
        <BCol class="d-flex justify-content-center">
          <div class="text-black text-md">
            Masukkan ulang PIN Kamu
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChangeConfirmPin"
          />
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          {{ errorMessage }}
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2">
          <BButton
            variant="primary"
            :disabled="pinConfirm === '' || pinConfirm.length < 6 || isLoading || pinConfirm !== pin"
            @click="onSubmit"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Konfirmasi PIN
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </div>
</template>

<script>
import { komshipAxiosIns } from '@/libs/axios'
import CodeInput from 'vue-verification-code-input'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      isVisibility: true,
      visibilityPin: 'number',
      pin: '',
      pinConfirm: '',
      isLoading: false,
      errorMessage: '',
    }
  },
  computed: {
    handleChangeCreatePin() {
      return value => {
        this.pin = value
      }
    },
    handleChangeConfirmPin() {
      return value => {
        this.pinConfirm = value
      }
    },
    visiblePin() {
      return () => {
        this.isVisibility = !this.isVisibility
        this.visibilityPin = this.isVisibility ? 'number' : 'text'
      }
    },
  },
  methods: {
    // this conditional is temporary [reference task : https://app.clickup.com/t/86cvkj0xq]
    handlePushToCreatePIN() {
      this.$router.push('/setting-kompship/pin')
    },
    async onSubmit() {
      this.isLoading = true
      const url = '/v1/pin/store'
      const payload = {
        pin: this.pin,
      }
      if (this.pin === this.pinConfirm) {
        await komshipAxiosIns.post(url, payload)
          .then(() => {
            this.$bvModal.hide('modal-check-pin')
            this.$bvModal.hide('modal-create-pin')
            this.$bvModal.hide('modal-confirm-pin')
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Berhasil',
                icon: 'CheckCircleIcon',
                text: 'Berhasil membuat pin',
                variant: 'success',
              },
            }, { timeout: 2000 })
            this.isLoading = false
          })
          .catch(() => {
            this.loading.pin = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal membuat pin',
                variant: 'danger',
              },
            }, { timeout: 2000 })
          })
      } else {
        this.errorMessage = 'PIN yang kamu masukkan tidak sama'
      }
    },
  },
}
</script>
