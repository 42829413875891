<template>
  <div
    class="p-[12px] md:p-[24px] rounded-[8px] border-[1px] flex flex-col justify-center border-[#E2E2E2] radius-[8px]"
  >
    <div class="flex items-center gap-[6px]">
      <img
        src="https://storage.googleapis.com/komerce/icon/icon-trpohy.svg"
        alt="thropy"
      >
      <div class="text-[12px] text-[#333333]">
        Misi
      </div>
      <div
        v-show="renderBanner === 'active'"
        id="tooltipInfo"
        class="flex gap-[5px] bg-[#FFEDED] text-[#E31A1A] items-center rounded-full p-[5px]"
      >
        <span class="k-info-circle" />
        <div class="text-[12px]">
          {{ countdownTimer(dataGamification.expired_at) }}
        </div>
      </div>
      <b-popover
        triggers="hover"
        target="tooltipInfo"
        placement="topRight"
        custom-class="custom-popover"
      >{{ tooltipText }}</b-popover>
    </div>

    <!-- START : Conditional Banner Gamification -->

    <!-- pending banner -->
    <div
      v-show="renderBanner === 'pending'"
      class="gap-[6px] p-[12px] bg-[#DFF3FF] mt-[12px]  flex items-start rounded-[8px]"
    >
      <span
        class="k-info-circle-1 text-[20px] mt-[2px]"
        :class="'text-[#0F679A]'"
      />
      <div class="text-[12px] text-[#333333]">
        Tingkatkan spending bulanan kamu untuk mendapatkan
        <b>Akun Whitelisted Meta free</b> topup, free sewa dan unlimited!
      </div>
    </div>

    <!-- expired banner -->
    <div
      v-show="renderBanner === 'expired'"
      class="gap-[6px] p-[12px] bg-[#F8F8F8] mt-[12px] flex items-start rounded-[8px]"
    >
      <span
        class="k-info-circle-1 text-[20px] mt-[2px]"
        :class="'text-[#333333]'"
      />
      <div class="text-[12px] text-[#333333]">
        Klaim Akun Whitelist mu sudah kadaluwarsa untuk bulan ini. Lanjutkan
        Spending di bulan selanjutnya untuk mendapatkan akun WL unlimited gratis
      </div>
    </div>

    <!-- active banner -->
    <div
      v-show="renderBanner === 'active'"
      class="gap-[6px] p-[12px] bg-[#DCF3EB] mt-[12px] flex items-start rounded-[8px]"
    >
      <span
        class="k-tick-circle text-[20px] mt-[2px]  text-[#10673E]"
      />
      <div>
        <div class="text-[12px] lg:text-[14px] text-[#333333] font-bold">
          Klaim WL Sekarang juga!
        </div>
        <div class="text-[12px] text-[#333333]">
          Selamat! Kamu mendapatkan gratis unlimited akun Whitelist Komads. Yuk
          klaim sekarang juga!
        </div>
        <div class="hidden-button-2  mt-[8px]">
          <button
            class="bg-[#34A770] text-white text-[12px] w-[150px] gap-[8px] flex justify-center font-bold rounded-[8px] text-nowrap inline-block p-[8px]"
            @click="handleClaimGamification"
          >
            <img
              src="https://storage.googleapis.com/komerce/icon/icon-trophy-less.svg"
              alt="thropy"
            >
            Klaim Sekarang
          </button>
        </div>
      </div>
      <div class="hidden-button grow flex justify-end">
        <button
          class="bg-[#34A770] text-white text-[12px] w-[150px] gap-[8px] flex justify-center font-bold rounded-[8px] text-nowrap p-[8px]"
          @click="handleClaimGamification"
        >
          <img
            src="https://storage.googleapis.com/komerce/icon/icon-trophy-less.svg"
            alt="thropy"
          >
          Klaim Sekarang
        </button>
      </div>
    </div>
    <!-- END : Conditional Banner Gamification -->
    <div
      class="mt-[8px] wrapper-blur"
      :class="renderProgress === 'inactive'"
    >
      <div
        v-if="renderProgress === 'inactive'"
        class="blur-content"
      >
        <div
          class="border-[1px] border-[#E2E2E2] shadow-md px-[4px] py-[8px] rounded-md bg-white text-[12px]"
        >
          Anda sudah menyelesaikan semua level
        </div>
      </div>
      <div class="grid grid-cols-2  md:grid-cols-4  gap-[8px]">
        <div
          v-for="data in levelingGamification"
          :key="data.title"
        >
          <div
            :class="[
              RenderIndexActive().length > 0 ? RenderIndexActive()[0].title : '' === data.title
                ? 'border-[#34A770]'
                : 'border-[#E2E2E2]',
              spendingGamification >= data.valueMinimum
                ? 'bg-[#f4f4f4]'
                : 'bg-white'
            ]"
            class="justify-center flex flex-col rounded-[8px] h-[80px]  p-[10px] border-[1px] "
          >
            <div class="flex gap-[6px]">
              <div class="text-[#828282] text-[10px]">
                {{ data.title }}
              </div>
              <div
                v-show="spendingGamification >= data.valueMinimum"
                class="bg-[#DCF3EB] rounded-full w-[16px] h-[16px] flex justify-center items-center"
              >
                <span class="k-tick-circle text-[#34A770] text-[14px]" />
              </div>
            </div>
            <div class="text-[#333333] font-bold text-[16px] mt-[6px]">
              {{ data.labelCurrentcy }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-[10px] mt-[8px]">
        <div class="w-[95%]">
          <BProgress
            id="progress"
            class="mt-[10px] custom-progress"
            :value="spendingGamification"
            :max="maxSpendingGamification"
            variant="success"
          />
        </div>
        <div class="-mb-[10px] text-[12px] text-[#333333] text-nowrap">
          {{ RenderPercentProgress }}
        </div>
      </div>
      <div class="grid grid-cols-2  gap-[6px] w-[95%] mt-[6px]">
        <div class="text-[#333333] text-[12px] sm:text-[14px] font-bold border-right-rupiah">
          {{ IDR(spendingGamification, 2, 2) }}
        </div>
        <div
          :class="
            spendingGamification >= maxSpendingGamification &&
              'text-[#333333] font-bold'
          "
          class="text-right text-[12px] sm:text-[14px]"
        >
          {{ IDR(maxSpendingGamification, 2, 2) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'

export default {
  name: 'Gamification',
  props: {
    levelingGamification: {
      type: Array,
      default: () => [],
    },
    maxSpendingGamification: {
      type: Number,
      default: 0,
    },
    spendingGamification: {
      type: Number,
      default: 0,
    },
    dataGamification: {
      type: Object,
      default: () => {},
    },
    renderBanner: {
      type: String,
      default: '',
    },
    renderProgress: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IDR,
      tooltipText: 'Waktu yang tersisa untuk kamu klaim akun whitelist.',
    }
  },
  computed: {
    RenderPercentProgress() {
      const progress = Math.floor(
        (this.spendingGamification / this.maxSpendingGamification) * 100,
      )

      if (progress > 100) {
        return `${100}%`
      }

      return `${progress} %`
    },
  },
  methods: {
    RenderIndexActive() {
      const filterPassed = this.levelingGamification.filter(
        e => e.valueMinimum > this.spendingGamification,
      )
      return filterPassed
    },
    handleClaimGamification() {
      this.$emit('claim-gamification')
    },
    countdownTimer(expiredAt) {
      // Mengubah string tanggal menjadi objek Date
      const achieved = new Date()
      const expired = new Date(expiredAt)

      // Menghitung selisih waktu dalam milidetik
      const difference = expired - achieved

      // Mengkonversi selisih waktu ke dalam hari dan sisa detik
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const remainingSeconds = difference % (1000 * 60 * 60 * 24)

      // Menghitung jam dari sisa detik
      const hours = Math.floor(remainingSeconds / (1000 * 60 * 60))

      // Mengembalikan hasil dalam bentuk string
      if (days > 0) {
        return `${days} hari`
      }
      return `${hours} jam`
    },
  },
}
</script>

<style scoped>
.custom-progress {
  background-color: #f4f4f4 !important; /* Custom color */
}

.hidden-button {
  display: none;
}

.border-right-rupiah {
  border-right: 1px solid #E2E2E2;
}

@media screen and (min-width: 1025px) {
  .hidden-button {
    display: flex;
  }
  .hidden-button-2 {
    display: none;
  }

  .border-right-rupiah {
    border-right: none;
  }
}

.wrapper-blur {
  position: relative;
}

.blur-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
}

.custom-popover .popover-body {
  color: green !important; /* Mengubah warna teks konten popover */
}

.custom-popover .popover-header {
  color: orange !important; /* Mengubah warna teks judul popover */
}
</style>
