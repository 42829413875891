<template>
  <BModal
    :id="idModal"
    :ref="idModal"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <BRow class="justify-center my-1 flex flex-column">
      <BCol class="d-flex justify-content-center">
        <BImg
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
      </BCol>
      <BCol class="d-flex justify-content-center mt-1">
        <div class="font-medium text-black text-xl">
          {{ title }}
        </div>
      </BCol>
      <BCol class="d-flex justify-content-center my-1">
        <div class="font-normal text-black text-base text-center">
          {{ desc }}
        </div>
      </BCol>
      <BRow class="flex flex-row justify-center gap-3 mx-2">
        <BCol class="d-flex justify-content-center">
          <BButton
            block
            size="sm"
            variant="outline-primary"
            @click="$bvModal.hide(idModal)"
          >
            Batal
          </BButton>
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            block
            size="sm"
            variant="primary"
            :disabled="loading"
            @click="submit"
          >
            <BSpinner
              v-if="loading"
              variant="light"
              small
            />
            {{ submitButton }}
          </BButton>
        </BCol>
      </BRow>
    </BRow>
  </BModal>
</template>

<script>
export default {
  props: {
    idModal: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    submitButton() {
      return this.source === 'verif' ? 'Konfirmasi' : 'Ajukan Verifikasi'
    },
    variantCancelButton() {
      return this.source === 'verif' ? 'outline-primary' : 'primary'
    },
  },
}
</script>
