<template>
  <div>
    <BModal
      id="modal-change-card-name"
      ref="modal-change-card-name"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <ValidationObserver #default="{ invalid }">
        <BRow class="justify-center my-1 flex flex-column gap-4">
          <BCol class="flex justify-center">
            <img
              src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
              alt="Komerce"
              width="100"
            >
          </BCol>
          <BCol class="d-flex justify-content-center text-black font-semibold text-xl">
            Ubah Nama Kartu
          </BCol>
          <BCol class="d-flex justify-content-center text-black font-normal text-lg text-center">
            Kamu bisa mengubah nama kartu berdasarkan fungsi dan kebutuhanmu
          </BCol>
          <BCol class="justify-content-center">
            <BFormGroup>
              <template #label>
                <div class="text-black">
                  Nama kartu saat ini
                </div>
              </template>
              <BFormInput
                v-model="card.label"
                disabled
              />
            </BFormGroup>
          </BCol>
          <BCol class="justify-content-center">
            <BFormGroup>
              <template #label>
                <div class="text-black">
                  <strong class="text-primary">*</strong>
                  <span>Nama kartu baru</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nama kartu baru"
                :rules="{required: true, min: 3, max:15}"
              >
                <BFormInput
                  v-model="label"
                  placeholder="Nama kartu baru"
                  :state="errors.length > 0 ? false:null"
                  @keydown="onInput($event)"
                  @paste="onPaste($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow class="mb-1">
          <BCol cols="6">
            <BButton
              variant="outline-primary"
              block
              @click="
                $refs['modal-change-card-name'].hide()
                label = null
              "
            >
              Batal
            </BButton>
          </BCol>
          <BCol cols="6">
            <BButton
              :disabled="invalid"
              :variant="invalid ? 'secondary' : 'primary'"
              type="submit"
              block
              @click.prevent="handleUpdateCardName"
            >
              <BSpinner
                v-if="isLoading"
                variant="light"
                small
              />
              Ubah Nama
            </BButton>
          </BCol>
        </BRow>
      </ValidationObserver>
    </BModal>

    <BModal
      id="modal-confirmation-change-card-name"
      ref="modal-confirmation-change-card-name"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md text-center">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChangePin"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleClose"
          >
            Kembali
          </BButton>
          <BButton
            :variant="pin.length < 6 || isLoading ? 'secondary' : 'primary'"
            :disabled="pin.length < 6 || isLoading"
            type="submit"
            @click.prevent="onSubmit"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import { newAxiosIns } from '@/libs/axios'
import CodeInput from 'vue-verification-code-input'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CodeInput,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    getCards: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      min,
      max,
      isLoading: false,
      label: null,
      isVisibility: true,
      visibilityPin: 'number',
      pin: '',
      errorMessage: '',
    }
  },
  methods: {
    onInput(event) {
      const isLetter = (event.key >= 'a' && event.key <= 'z') || (event.key >= 'A' && event.key <= 'Z')
      const isNumber = event.key >= '0' && event.key <= '9'
      const isControlKey = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Space'].includes(event.key)

      if (!(isLetter || isNumber || isControlKey || event.key === ' ')) {
        event.preventDefault()
      }
    },
    onPaste(event) {
      // Get pasted data
      const pastedText = (event.clipboardData || window.clipboardData).getData('text')
      // if pasted text is not a number or a letter
      if (!/^[a-zA-Z0-9\s]+$/.test(pastedText)) {
        event.preventDefault()
      }
    },
    async onSubmit() {
      this.isLoading = true
      const url = `/komcards/api/v1/card/${this.card.id}/update`
      const body = {
        label: this.label,
        pin: this.pin,
      }
      await newAxiosIns.put(url, body)
        .then(() => {
          this.isLoading = false
          this.errorMessage = ''
          this.label = ''
          this.pin = ''
          this.$toast_success({ message: 'Nama kartu berhasil diubah' })
          this.$bvModal.hide('modal-confirmation-change-card-name')
          this.$bvModal.hide('modal-change-card-name')
          this.getCards()
        })
        .catch(err => {
          const { message } = err.response.data.meta
          this.isLoading = false
          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
            return
          }
          this.$toast_error({ message: 'Terjadi kesalahan sementara. Mohon coba lagi dalam beberapa saat' })
        })
    },
    handleUpdateCardName() {
      this.$bvModal.show('modal-confirmation-change-card-name')
      this.$bvModal.hide('modal-change-card-name')
    },
    visiblePin() {
      this.isVisibility = !this.isVisibility
      this.visibilityPin = this.isVisibility ? 'number' : 'text'
    },
    handleChangePin(value) {
      this.pin = value
    },
    handleClose() {
      this.pin = ''
      this.errorMessage = ''
      this.$bvModal.hide('modal-confirmation-change-card-name')
      this.$bvModal.show('modal-change-card-name')
    },
  },
}
</script>
