<template>
  <BModal
    id="modal-info-balance"
    ref="modal-info-balance"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <BRow class="justify-center my-1 flex flex-column">
      <BCol>
        <img
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          alt="Komerce"
          class="m-auto"
        >
      </BCol>
      <BCol class="text-black text-xl text-center my-1 font-bold">
        Saldo Kompay tidak mencukupi
      </BCol>
      <BCol class="text-[#222222] text-md text-center">
        Silahkan Top Up Kompay anda untuk bisa membuat kartu baru. Minimal saldo kompay <span class="text-primary">{{ IDR(minSaldoKompay) }}</span>
      </BCol>
      <BCol class="text-[#222222] text-md text-center mt-1">
        Saldo Kompay : <span class="text-primary">{{ IDR(balance) }}</span>
      </BCol>
      <BRow class="flex flex-row justify-center gap-3 mt-2">
        <BButton
          variant="outline-primary"
          @click="$bvModal.hide('modal-info-balance')"
        >
          Batal
        </BButton>
        <BButton
          variant="primary"
          @click="$router.push('/')"
        >
          Top Up Saldo
        </BButton>
      </BRow>
    </BRow>
  </BModal>
</template>

<script>
import { IDR } from '@/libs/currency'

export default {
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    minSaldoKompay: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      IDR,
    }
  },
}
</script>
