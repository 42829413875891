<template>
  <BModal
    id="modal-confirmation"
    ref="modal-confirmation"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <BRow class="justify-center my-1 flex flex-column">
      <BCol class="d-flex justify-content-center">
        <div class="text-black font-semibold text-xl">
          Verifikasi PIN
        </div>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1">
        <div class="text-black text-md text-center">
          Mohon verifikasi identitias kamu dengan memasukkan PIN
        </div>
      </BCol>
      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibilityPin"
          :field-width="40"
          @change="handleChange"
        />
      </BCol>
      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="visiblePin"
        >
          {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
        </BButton>
      </BCol>
      <BCol
        class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
        @click="$router.push('/setting-kompship/pin')"
      >
        Lupa PIN?
      </BCol>
      <BCol class="d-flex justify-content-center text-primary">
        <small class="text-danger text-center">{{ errorMessage }}</small>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
        <BButton
          variant="outline-primary"
          @click="handleClose"
        >
          Kembali
        </BButton>
        <BButton
          :variant="pin.length < 6 || loading.create || loading.topup || loading.withdrawal ? 'secondary' : 'primary'"
          :disabled="pin.length < 6 || loading.create || loading.topup || loading.withdrawal"
          type="submit"
          @click.prevent="onSubmit()"
        >
          <BSpinner
            v-if="loading.create || loading.topup || loading.withdrawal"
            variant="light"
            small
          />
          Submit
        </BButton>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import CodeInput from 'vue-verification-code-input'
import { newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'

export default {
  components: { CodeInput },
  props: {
    source: {
      type: String,
      default: null,
    },
    payloadCreate: {
      type: Object,
      default: () => ({}),
    },
    payloadTopup: {
      type: Object,
      default: () => ({}),
    },
    cardData: {
      type: Object,
      default: () => ({}),
    },
    getCards: {
      type: Function,
      default: () => {},
    },
    getMutation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isVisibility: true,
      visibilityPin: 'number',
      errorMessage: '',
      pin: '',
      loading: {
        create: false,
        topup: false,
        withdrawal: false,
      },
      showError: false,
      IDR,
    }
  },
  computed: {
    handleChange() {
      return value => {
        this.pin = value
      }
    },
    visiblePin() {
      return () => {
        this.isVisibility = !this.isVisibility
        this.visibilityPin = this.isVisibility ? 'number' : 'text'
      }
    },
    handleClose() {
      return () => {
        this.pin = ''
        if (['topup', 'withdrawal'].includes(this.source)) {
          this.$bvModal.hide('modal-confirmation')
          this.$bvModal.show('modal-topup')
          this.errorMessage = ''
        }
        this.$bvModal.hide('modal-confirmation')
        this.errorMessage = ''
      }
    },
  },
  methods: {
    async onCreate() {
      this.loading.create = true
      const url = '/komcards/api/v1/card/create'
      const payload = {
        ...this.payloadCreate,
        pin: this.pin,
      }
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.loading.create = false
          this.$router.push('/komcards')
          const message = 'Permintaan pembuatan kartu komcards telah diajukan. Harap tunggu selama 1x24 jam'
          this.$toast_success({ message })
          this.payloadCreate = {}
          this.pin = ''
        })
        .catch(err => {
          const { message, code } = err.response.data
          this.loading.create = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
            return
          }
          if (message === 'card holder id not found') {
            this.errorMessage = 'Terjadi kesalahan ketika membuat kartu, mohon hubungi team cs kami untuk lebih lanjut'
            return
          }
          if (message === 'kompoints balance under minimal nominal') {
            this.errorMessage = 'Maaf, jumlah Kompoints yang kamu pakai kurang dari minimal nominal penggunaan. Silahkan cek kembali pengaturan pembayaran yang kamu atur'
            return
          }
          if (code === 500) {
            this.errorMessage = 'Terjadi kesalahan ketika membuat kartu, mohon hubungi team cs kami untuk lebih lanjut'
            return
          }

          this.errorMessage = message
        })
    },
    async onTopup() {
      this.loading.topup = true
      const payload = {
        ...this.payloadTopup,
        pin: this.pin,
      }
      const url = '/komcards/api/v1/card/topup'
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.loading.topup = false
          const message = `Kamu berhasil melakukan top up ke kartu ${this.cardData.label} (${this.cardData.card_number})`
          this.$toast_success({ message })
          this.$bvModal.hide('modal-confirmation')
          this.$bvModal.hide('modal-topup')
          this.pin = ''
          this.getCards()
          this.getMutation()
          this.$emit('resetPayload')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
        .catch(err => {
          const { message } = err.response.data
          this.loading.topup = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
            return
          }
          if (message === 'insufficient kompay balance') {
            this.errorMessage = 'Saldo anda tidak mencukupi'
            return
          }
          if (message === 'pending transaction found, cannot proceed') {
            this.errorMessage = 'Maaf, top up belum dapat dilakukan karena masih terdapat transaksi sebelumnya yang belum selesai. Silakan coba beberapa saat lagi'
            return
          }
          this.errorMessage = 'Terjadi kesalahan ketika topup, mohon hubungi team cs kami untuk lebih lanjut'
        })
    },
    async onWithdrawal() {
      this.loading.withdrawal = true
      const payload = {
        ...this.payloadTopup,
        pin: this.pin,
      }
      const url = '/komcards/api/v1/card/refund'
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.loading.withdrawal = false
          const message = `Kamu berhasil melakukan penarikan saldo Komcards sebesar ${this.IDR(this.payloadTopup.balance)}`
          this.$toast_success({ message })
          this.$bvModal.hide('modal-confirmation')
          this.$bvModal.hide('modal-topup')
          this.getCards()
          this.getMutation()
          this.$emit('resetPayload')
          this.pin = ''
        })
        .catch(err => {
          const { message } = err.response.data
          this.loading.withdrawal = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
            return
          }
          if (message === 'insufficient kompay balance') {
            this.errorMessage = 'Saldo anda tidak mencukupi'
            return
          }
          this.errorMessage = 'Terjadi kesalahan ketika penarikan, mohon hubungi team cs kami untuk lebih lanjut'
        })
    },
    onSubmit() {
      if (this.source === 'create') {
        this.onCreate()
      }
      if (this.source === 'topup') {
        this.onTopup()
      }
      if (this.source === 'withdrawal') {
        this.onWithdrawal()
      }
      return ''
    },
  },
}
</script>
