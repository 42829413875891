<template>
  <BModal
    id="download-mutation-transaction-komcards"
    ref="download-mutation-transaction-komcards"
    hide-footer
    hide-header
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    @hidden="onReset"
  >
    <ValidationObserver #default="{ invalid }">
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center mb-2">
          <strong class="text-black text-xl">
            Download Mutasi
          </strong>
        </BCol>
        <BCol>
          <BFormGroup>
            <template #label>
              <span class="text-black font-medium text-base">Rentang Waktu</span>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Rentang Waktu"
              rules="required"
            >
              <DateRangePicker
                ref="picker"
                v-model="dateRange"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'center'"
                class="w-full"
                :max-date="new Date()"
              >
                <template v-slot:input="picker">
                  {{ SLASH_DATE(picker.startDate) }} - {{ SLASH_DATE(picker.endDate) }}
                </template>
              </DateRangePicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol>
          <BFormGroup>
            <template #label>
              <div class="text-black flex items-center">
                <span class="font-medium text-base">Kartu</span>
                <span class="text-primary">*</span>
              </div>
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Kartu"
              rules="required"
            >
              <VSelect
                v-model="cardId"
                placeholder="Pilih kartu"
                label="card_name"
                :options="optionsCards"
                :reduce="option => option.value"
              >
                <template #no-options>
                  Tidak ada kartu
                </template>
                <template #option="{ card_name }">
                  <div class="flex items center gap-2">
                    <img
                      class="w-6"
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
                      alt="komcard"
                    >
                    <span class="text-black">{{ card_name }}
                    </span>
                  </div>
                </template>
              </VSelect>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow class="mb-1">
        <BCol cols="6">
          <BButton
            variant="outline-primary"
            block
            @click="onClose"
          >
            Batal
          </BButton>
        </BCol>
        <BCol cols="6">
          <BButton
            :disabled="invalid || isLoading"
            :variant="invalid || isLoading ? 'secondary' : 'primary'"
            type="submit"
            block
            @click.prevent="onSubmit"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Download
          </BButton>
        </BCol>
      </BRow>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import { required } from '@validations'
import {
  LABELDAY, LABELMONTH, SLASH_DATE, YYYYMMDD, DASH_DATE,
} from '@/libs/formatDate'
import {
  firstDateOfMonth, last60, last90, lastDateOfLastMonth, lastMonth, today,
} from '@/store/helpers'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
    DateRangePicker,
  },
  props: {
    listCards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      SLASH_DATE,
      YYYYMMDD,
      DASH_DATE,
      required,
      cardId: null,
      isLoading: false,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      ranges: {
        'Bulan ini': [firstDateOfMonth, today],
        'Bulan lalu': [lastMonth, lastDateOfLastMonth],
        '2 Bulan terakhir': [last60, today],
        '3 Bulan terakhir': [last90, today],
      },
    }
  },
  computed: {
    optionsCards() {
      return this.listCards.map(card => ({
        ...card,
        value: card.id,
        card_name: `${card.label} *${this.splitCardNumber(card.card_number)}`,
      }))
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true
      const url = '/komcards/api/v1/card/mutation/export'
      const body = {
        card_id: this.cardId,
        start_date: YYYYMMDD(this.dateRange.startDate),
        end_date: YYYYMMDD(this.dateRange.endDate),
      }
      await newAxiosIns.post(url, body)
        .then(res => {
          const { data } = res.data

          const filteredCard = this.listCards.find(card => card.id === this.cardId)
          const filename = `Rincian_Mutasi_${filteredCard.label}_${DASH_DATE(this.dateRange.startDate)}_${DASH_DATE(this.dateRange.endDate)}`

          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.base64_object}`
          a.download = `${filename}.xls`
          a.click()

          this.isLoading = false
          this.dateRange = {
            startDate: firstDateOfMonth,
            endDate: today,
          }
          this.$bvModal.hide('download-mutation-transaction-komcards')
          this.$toast_success({ message: 'Data berhasil di export' })
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Terjadi kesalahan sementara. Mohon coba lagi dalam beberapa saat' })
        })
    },
    onClose() {
      this.$bvModal.hide('download-mutation-transaction-komcards')
    },
    onReset() {
      this.cardId = null
    },
    splitCardNumber(number) {
      return number.split(' ').pop()
    },
  },
}
</script>
